import { Button, Card, CardContent, Typography } from "@mui/material";
import { verticalComponentMargin } from "../../../../utils/styleConstants";
import LabelValueText from "../../../../components/LabelValueText";
import GreyCover from "../../../../components/GreyCover";
import api from "../../../../api";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import handleError from "../../../../utils/handleError";
import SyncIcon from "@mui/icons-material/Sync";

const FssaiDetails = ({ fssaiDetails }) => {
  const { kitchenRegistrationId } = useParams();
  const { fssaiNumber, sourceDetails } = fssaiDetails;

  const updateFssaiWithSource = async () => {
    try {
      await api.kitchenRegistrations.updateFssaiWithSource({
        kitchenRegistrationId,
      });
      toast.success("Fssai source details updated successfully");
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Card style={{ margin: verticalComponentMargin }}>
      <CardContent>
        <Typography variant="subtitle1" fontWeight={"bold"}>
          Fssai Details
        </Typography>
        <LabelValueText label={"Fssai number"} value={fssaiNumber} />
        {sourceDetails?.status === "pending" && (
          <Button
            size="small"
            variant="contained"
            endIcon={<SyncIcon />}
            color="warning"
            fullWidth
            style={{ marginTop: "10px" }}
            onClick={updateFssaiWithSource}
          >
            Update Fssai with source
          </Button>
        )}
        {sourceDetails?.status === "success" && (
          <GreyCover>
            <Typography variant="subtitle1" fontWeight={"bold"}>
              Source details
            </Typography>
            <LabelValueText
              label={"Business name"}
              value={sourceDetails.businessName}
            />
            <LabelValueText label={"Address"} value={sourceDetails.address} />
            <LabelValueText
              label={"License status"}
              value={sourceDetails.licenseStatus}
            />
          </GreyCover>
        )}
      </CardContent>
    </Card>
  );
};

export default FssaiDetails;
