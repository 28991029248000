import AccessDeniedPage from "../pages/AccessDeniedPage";
import ResetPasswordPage from "../pages/Auth/ResetPasswordPage";
import LoginWithOtpPage from "../pages/Auth/LoginWithOtpPage";
import KitchenRegistrationsPage from "../pages/KitchenRegistrationsPage";
import KitchenRegistrationPage from "../pages/KitchenRegistrationPage";
import OrdersPage from "../pages/OrdersPage";
import PaymentsPage from "../pages/PaymentsPage";
import RefundsPage from "../pages/RefundsPage";
import RefundPage from "../pages/RefundPage";
import SettlementsPage from "../pages/SettlementsPage";
import SettlementPage from "../pages/SettlementPage";
import OrderPage from "../pages/OrderPage";
import DeliveryPage from "../pages/DeliveryPage";
import PaymentPage from "../pages/PaymentPage";
import UnprocessedExpiredOrdersPage from "../pages/UnprocessedExpiredOrdersPage";
import UnprocessedExpiredPaymentsPage from "../pages/UnprocessedExpiredPaymentsPage";
import invoiceRoutes from "../modules/invoices/invoiceRoutes";

const routes = [
  {
    path: "access-denied",
    component: <AccessDeniedPage />,
    isProtected: true,
  },
  {
    path: "auth/reset-password",
    component: <ResetPasswordPage />,
    isProtected: false,
  },
  {
    path: "auth/login-with-otp",
    component: <LoginWithOtpPage />,
    isProtected: false,
  },
  {
    path: "/kitchen-registrations",
    component: <KitchenRegistrationsPage />,
    isProtected: true,
  },
  {
    path: "/kitchen-registrations/:kitchenRegistrationId",
    component: <KitchenRegistrationPage />,
    isProtected: true,
  },
  {
    path: "/orders",
    component: <OrdersPage />,
    isProtected: true,
  },
  {
    path: "/orders/expired/unprocessed",
    component: <UnprocessedExpiredOrdersPage />,
    isProtected: true,
  },
  {
    path: "/orders/:orderId",
    component: <OrderPage />,
    isProtected: true,
  },
  {
    path: "orders/:orderId/delivery/:date",
    component: <DeliveryPage />,
    isProtected: true,
  },
  {
    path: "/payments",
    component: <PaymentsPage />,
    isProtected: true,
  },
  {
    path: "/payments/:paymentId",
    component: <PaymentPage />,
    isProtected: true,
  },
  {
    path: "/payments/expired/unprocessed",
    component: <UnprocessedExpiredPaymentsPage />,
    isProtected: true,
  },
  {
    path: "/refunds",
    component: <RefundsPage />,
    isProtected: true,
  },
  {
    path: "/refunds/:refundId",
    component: <RefundPage />,
    isProtected: true,
  },
  {
    path: "/settlements",
    component: <SettlementsPage />,
    isProtected: true,
  },
  {
    path: "/settlements/:settlementId",
    component: <SettlementPage />,
    isProtected: true,
  },
  ...invoiceRoutes,
];

export default routes;
