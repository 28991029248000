import axios from "axios";
import getAll from "../utils/getAll";

let base = "/kitchenRegistrations/";

const kitchenRegistrations = {
  getAllKitchenRegistrations: getAll(base),
  getKitchenRegistrationById: async (id) => (await axios.get(base + id)).data,
  approve: async (id, data) =>
    (await axios.patch(base + id + "/approve", data)).data,
  decline: async (id, data) =>
    (await axios.patch(base + id + "/decline", data)).data,
  updatePanWithSource: async ({ kitchenRegistrationId }) =>
    (await axios.patch(base + kitchenRegistrationId + "/panSource")).data,
  updateGstWithSource: async ({ kitchenRegistrationId }) =>
    (await axios.patch(base + kitchenRegistrationId + "/gstSource")).data,
  updateFssaiWithSource: async ({ kitchenRegistrationId }) =>
    (await axios.patch(base + kitchenRegistrationId + "/fssaiSource")).data,
};

export default kitchenRegistrations;
