import React from "react";
import { Card, CardContent, Grid, Typography, Box } from "@mui/material";

const InvoiceList = ({ invoices }) => {
  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        {invoices.map((invoice) => (
          <Grid item xs={12} sm={6} md={4} key={invoice._id}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" color="primary">
                  {invoice.invoiceNumber}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Invoice Date:{" "}
                  {new Date(invoice.invoiceDate).toLocaleDateString()}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Customer: {invoice.customerDetails.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Item: {invoice.item.description}
                </Typography>
                <Typography variant="h6" color="primary" sx={{ marginTop: 1 }}>
                  Total: ₹{invoice.item.total}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default InvoiceList;
