import InvoicesPage from "./pages/InvoicesPage";

const invoiceRoutes = [
  {
    path: "/invoices",
    component: <InvoicesPage />,
    isProtected: true,
  },
];

export default invoiceRoutes;
