import { Typography } from "@mui/material";
import PanDetails from "./PanDetails";
import GstDetails from "./GstDetails";
import FssaiDetails from "./FssaiDetails";

const LegalDetails = ({ legalDetails }) => {
  return (
    <>
      <Typography variant="h6" style={{ marginBottom: "10px" }}>
        Legal Details
      </Typography>
      <PanDetails panDetails={legalDetails.panDetails} />
      <GstDetails gstDetails={legalDetails.gstDetails} />
      <FssaiDetails fssaiDetails={legalDetails.fssaiDetails} />
    </>
  );
};

export default LegalDetails;
