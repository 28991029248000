import { Container, List, ListItem, ListItemText } from "@mui/material";
import Header from "../../../../components/Header/Header";
import Title from "../../../../components/Title";
import { useEffect, useState } from "react";
import api from "../../../../api";
import handleError from "../../../../utils/handleError";
import InvoiceList from "./components/InvoiceList";
import DownloadInvoiceListJson from "./components/DownloadInvoiceListJson";

const InvoicesPage = () => {
  const [invoices, setInvoices] = useState([]);

  const fetchAllInvoices = async () => {
    try {
      const {
        data: { invoices },
      } = await api.invoices.getAllInvoices();
      setInvoices(invoices);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchAllInvoices();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Title title={"Invoices"} />
        <InvoiceList invoices={invoices} />
        <DownloadInvoiceListJson invoices={invoices} />
      </Container>
    </>
  );
};

export default InvoicesPage;
