import React from "react";
import { Button } from "@mui/material";

const DownloadInvoiceListJson = ({ invoices }) => {
  // Function to prepare the GSTR-1 JSON data
  const prepareGSTR1Data = () => {
    // Map your invoices to the GSTR-1 JSON structure
    const gstr1Data = invoices.map((invoice) => ({
      // Example mapping; adjust according to the GSTR-1 format
      gstin: invoice.gstin,
      ret_period: invoice.ret_period,
      // Add other required fields here
    }));

    return gstr1Data;
  };

  // Function to download the JSON file
  const downloadJSON = () => {
    const gstr1Data = prepareGSTR1Data();
    const json = JSON.stringify(gstr1Data, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "gstr1_invoices.json";
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={downloadJSON}>
        Download GSTR-1 JSON
      </Button>
    </div>
  );
};

export default DownloadInvoiceListJson;
